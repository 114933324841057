import { debugError } from '@/utils/debugger.util'
// import MongoRealmService from "@/services/mongoRealm.service"

const GAPI_CLIENT_ID = '122243632422-t72ttg7tc76grunn588h0f3ud93no222.apps.googleusercontent.com'
const GAPI_KEY = 'AIzaSyAdgrsowuV2InRtXu_nHeOZNoXRtbW9zNs'

let GoogleAuth
let GoogleUser

const GoogleAuthService = {
  async init() {
    return new Promise((resolve, reject) => {
      try {
        window.gapi.load('client:auth2', async () => {
          try {
            await window.gapi.client.init({
              apiKey: GAPI_KEY,
              clientId: GAPI_CLIENT_ID,
              // discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
              scope: 'https://www.googleapis.com/auth/userinfo.email',
            })

            GoogleAuth = await window.gapi.auth2.getAuthInstance()
            resolve()
          } catch (error) {
            debugError('GoogleAuth init()', error)
            reject()
          }
        })
      } catch (error) {
        debugError('GoogleAuth signIn', error)
      }
    })
  },

  getSignedInState() {
    return GoogleAuth.isSignedIn.get()
  },

  getUserProfile() {
    GoogleUser = GoogleAuth.currentUser.get()
    const BasicProfile = GoogleUser.getBasicProfile()

    return {
      id: BasicProfile.getId(),
      firstName: BasicProfile.getGivenName(),
      lastName: BasicProfile.getFamilyName(),
      avatar: BasicProfile.getImageUrl(),
      email: BasicProfile.getEmail(),
    }
  },

  async signIn() {
    return GoogleAuth.signIn({
      scope: 'profile email',
      ux_mode: 'redirect',
      redirect_uri: `${window.location.origin}`,
    })
  },

  async signOut() {
    return GoogleAuth.signOut().then(resp => {
      console.log('signout resp', resp)
      GoogleUser = null
    })
  },
}

export default GoogleAuthService
