<template>
  <div>
    <v-dialog v-model="chat" transition="scale-transition" class="chat-container rounded-lg">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" @click="scrollHistory" fab large fixed bottom right
          ><v-icon>fas fa-comments-alt</v-icon></v-btn
        >
      </template>
      <v-card class="chat-window d-flex flex-column justify-space-between pt-2">
        <v-btn class="close-btn mr-n4 mt-n2 pa-4" @click="chat = false" absolute right small
          ><v-icon>far fa-times</v-icon></v-btn
        >
        <div class="chat-history pa-3 align-start flex-grow-1">
          <div v-if="groupChatLoading && !chatHistory.length" class="text-center py-10">
            <v-progress-circular indeterminate />
          </div>
          <div v-else>
            <div v-for="({ _id, name, createdAt, message }, idx) in chatHistory" :key="`${name}-${idx}`" class="mb-4">
              <div class="d-flex align-center">
                <span class="mr-3">
                  <v-icon v-if="name === 'System'" color="grey">fal fa-robot</v-icon>
                  <b v-else>{{ name }}</b> <i class="caption">{{ moment(createdAt).calendar() }}</i>
                </span>
                <v-btn
                  v-if="currentUser.admin"
                  color="error"
                  @click="deleteMessage(_id.toString(), name, createdAt)"
                  :loading="groupChatLoading"
                  small
                  absolute
                  right
                  >Delete</v-btn
                >
              </div>
              <div class="ml-9 pt-1 body-2">{{ message }}</div>
            </div>
          </div>
        </div>
        <div>
          <v-divider />
          <v-textarea
            v-model.trim="message"
            class="align-end rounded-0"
            rows="3"
            autofocus
            hide-details
            filled
            no-resize
          />
          <v-btn @click="submit" class="rounded-0" :loading="groupChatLoading" x-large block
            ><v-icon>fas fa-bullhorn</v-icon></v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import MongoRealmService from "@/services/mongoRealm.service"
import moment from 'moment'
import useUserAuth from '@/composables/useUserAuth'
import useGroupChat from '@/composables/useGroupChat'

export default {
  name: 'GroupChat',
  data() {
    return {
      chat: false,
      message: '',
    }
  },
  setup() {
    const { currentUser } = useUserAuth()
    const { groupChatLoading, initChat, chatHistory, sendMessage, deleteMessage } = useGroupChat()
    // initChat()

    return {
      groupChatLoading,
      initChat,
      currentUser,
      chatHistory,
      sendMessage,
      deleteMessage,
    }
  },
  methods: {
    moment,
    async scrollHistory() {
      await this.initChat()
      /* setTimeout(() => {
        const height = document.querySelector(".chat-window").offsetHeight
        document.querySelector(".chat-history").scrollTop = height
      }, 1000) */
    },
    submit() {
      if (this.message) {
        this.sendMessage(this.currentUser, this.message)
        this.message = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  height: 100%;
  max-width: 600px;
}
.chat-window {
  height: 100%;

  .close-btn {
    z-index: 10;
  }
}
textarea {
  width: 100%;
}
.chat-history {
  position: relative;
  overflow-y: auto;
  // height: 80%;
}
</style>
