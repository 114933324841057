<template>
  <v-row class="my-2 py-2 text-h6 align-center" :class="{ 'deep-orange rounded-lg': !user.approved }">
    <v-col cols="4" class="pl-6 d-flex justify-space-between">
      <v-btn v-if="!user.approved" fab x-small><v-icon>far fa-check</v-icon></v-btn>
      <span>{{ user.firstName }}</span>
    </v-col>
    <v-col cols="3" class="text-center">{{ user.points }}</v-col>
    <v-col class="text-center">
      <v-btn class="mr-5" color="error" fab small @click="punish" :disabled="!user.approved || !user.active"
        ><v-icon>far fa-minus</v-icon></v-btn
      >
      <v-btn color="success" fab small :disabled="isCurrentUserRow || !user.approved || !user.active" @click="reward"
        ><v-icon>far fa-plus</v-icon></v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import { computed } from "@vue/composition-api"
import useUserAuth from "@/composables/useUserAuth"
import useUserPoints from "@/composables/useUserPoints"

export default {
  name: "UserRow",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup({ user }) {
    const { currentUser } = useUserAuth()
    const { reward, punish, fetchingUsers } = useUserPoints()

    const isCurrentUserRow = computed(() => {
      return user.id === currentUser.value.id
    })
    return {
      fetchingUsers,
      currentUser,
      reward: () => {
        console.log("reward")
        if (currentUser.value.approved && currentUser.value.active) {
          reward({ actingUser: currentUser.value, targetUser: user, points: user.points })
        }
      },
      punish: () => {
        console.log("punish")
        if (currentUser.value.approved && currentUser.value.active) {
          punish({ actingUser: currentUser.value, targetUser: user, points: user.points })
        }
      },
      isCurrentUserRow,
    }
  },
}
</script>

<style lang="scss" scoped></style>
