const debug = localStorage.getItem("ftp_debugger")

export const debugLog = (...args) => {
  if (debug) {
    console.log("%c[DEBUG]", "background: orange; color: #333;", ...args)
  }
}
export const debugError = (...args) => {
  if (debug) {
    console.error("%c[ERROR]", "background: red; color: #fff;", ...args)
  }
}
