import { ref } from '@vue/composition-api'
import MongoRealmService from '@/services/mongoRealm.service'
import { debugLog } from '@/utils/debugger.util'

const chatHistory = ref([])
const groupChatLoading = ref(false)

export default function() {
  const initChat = async () => {
    groupChatLoading.value = true
    chatHistory.value = await MongoRealmService.getChatHistory()
    groupChatLoading.value = false
  }

  const sendMessage = (user, message) => {
    debugLog('sendMessage()', message)
    groupChatLoading.value = true
    MongoRealmService.createChatMessage({ user, message })
    groupChatLoading.value = false
    // handleChatUpdate(message)
  }

  const deleteMessage = (id, name, createdAt) => {
    console.log('deleteMessage')
    groupChatLoading.value = true
    MongoRealmService.deleteChatMessage({ name, createdAt })
    groupChatLoading.value = false
  }

  const handleChatUpdate = message => {
    if (message) {
      chatHistory.value.unshift(message)
    } else {
      initChat()
    }
  }

  // watch for chat changes
  // MongoRealmService.watchForChatChanges(handleChatUpdate)

  return {
    groupChatLoading,
    initChat,
    sendMessage,
    deleteMessage,
    chatHistory,
    handleChatUpdate,
  }
}
