import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"

Vue.use(VueRouter)

const routes = [
  // catch any undefined paths, like google auth redirects
  {
    path: "*",
    name: "Home",
    component: Home,
  },
]

const router = new VueRouter({
  routes,
})

export default router
