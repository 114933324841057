import * as Realm from 'realm-web'
import moment from 'moment'
import { requestWrapper } from '@/utils/api.util'
import { debugLog } from '@/utils/debugger.util'
import orderBy from 'lodash/orderBy'
import debounce from 'lodash/debounce'

const REALM_APP_ID = 'points-app-bsjqm'
const REALM_API_KEY = 'VlWnHsZg2vhd2aIHAjlitO6uIWNfBT7EN8LxbwLT20cWGADgNOzCdBZ5wKD50jBH'

const appConfig = {
  id: REALM_APP_ID,
  timeout: 10000, // timeout in number of milliseconds
}

const app = new Realm.App(appConfig)
let db = null
let usersCollection = null
let messagesCollection = null

const MongoRealmService = {
  /**
   * Connect to the DB
   */
  async connect() {
    const credentials = Realm.Credentials.apiKey(REALM_API_KEY)
    await app.logIn(credentials)
    const mongo = app.currentUser.mongoClient('mongodb-atlas')
    db = mongo.db('points-app')
    usersCollection = db.collection('users')
    messagesCollection = db.collection('messages')
  },

  /**
   * Create new user
   */
  async createUser(profile) {
    // new users must be approved
    profile = { ...profile, points: 0, approved: false, active: true, admin: false }

    const { success } = await requestWrapper(() => usersCollection.insertOne(profile), 'Create User')

    if (success) {
      return profile
    }

    return {}
  },

  /**
   * Get all users
   */
  async getAllUsers(isAdmin) {
    // only admin can see all users
    let query = {}
    if (!isAdmin) {
      query = { approved: true, active: true }
    }
    const { data } = await requestWrapper(() => usersCollection.find(query), 'Get All Users')
    return orderBy(data, ['approved', 'points', 'firstName'], ['desc', 'desc', 'asc'])
  },

  /**
   * Get user by email address
   * @param {string} email The user's email
   */
  async getUserByEmail(email) {
    const { data } = await requestWrapper(() => usersCollection.findOne({ email }), 'Get User By Email')
    return data
  },

  /**
   * Get user by id
   * @param {string} id The user's id
   */
  /* const getUserById = async (id) => {
  const { data } = await requestWrapper(() => usersCollection.findOne({ id }), "Get User By ID")
  return data
} */
  /**
   * Update a user's points balance
   * Debounced to ensure fast clicking is enabled
   */
  updateUserPoints: debounce(async ({ id, points }) => {
    const { data } = await requestWrapper(
      () => usersCollection.updateOne({ id }, { $set: { points } }),
      'Update User Points'
    )

    return data
  }, 300),

  async getChatHistory() {
    const { data } = await requestWrapper(async () => {
      const history = await messagesCollection.find({}, { sort: { createdAt: -1 }, limit: 100 })
      return history //.reverse().slice(0, 100)
      /* return orderBy(history, ["dateCreated"], ["desc"])
        .slice(0, 50)
        .reverse() */
    }, 'Get Chat History')

    return data
  },

  async createChatMessage({ user, message }) {
    return requestWrapper(() =>
      messagesCollection.insertOne({
        userId: user.id,
        name: `${user.firstName}${user.lastName ? ` ${user.lastName.charAt(0)}.` : ''}`,
        message,
        createdAt: moment().format(),
      })
    )
  },

  deleteChatMessage({ name, createdAt }) {
    return requestWrapper(() => messagesCollection.deleteOne({ name, createdAt }), 'Delete Message')
  },

  async watchForUserChanges(callback) {
    for await (const change of usersCollection.watch()) {
      const { operationType } = change
      switch (operationType) {
        case 'insert': {
          const { documentKey, fullDocument: user } = change
          debugLog(`Users Watcher - insert: ${documentKey}`, user)
          callback(user)
          break
        }
        case 'update': {
          const { documentKey, fullDocument: user } = change
          debugLog(`Users Watcher - update: ${documentKey}`, user)
          callback(user)
          break
        }
      }
    }
  },

  async watchForChatChanges(callback) {
    for await (const change of messagesCollection.watch()) {
      const { operationType } = change
      switch (operationType) {
        case 'insert': {
          const { documentKey, fullDocument: message } = change
          debugLog(`Messages Watcher - insert: ${documentKey}`, message)
          callback(message)
          break
        }
        case 'update': {
          const { documentKey, fullDocument: message } = change
          debugLog(`Messages Watcher - update: ${documentKey}`, message)
          callback(message)
          break
        }
        case 'delete': {
          const { documentKey, fullDocument: message } = change
          debugLog(`Messages Watcher - delete: ${documentKey}`, message)
          callback()
          break
        }
      }
    }
  },
}

export default MongoRealmService
