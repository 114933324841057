import Vue from "vue"
import "@/lib/composition-api"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
// import store from "./store"
import router from "./router"
import MongoRealmService from "@/services/mongoRealm.service"

Vue.config.productionTip = false

import "./assets/main.scss"

// wait for DB connection
MongoRealmService.connect().finally(() => {
  new Vue({
    vuetify,
    // store,
    router,
    render: (h) => h(App),
  }).$mount("#app")
})
