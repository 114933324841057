var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{staticClass:"chat-container rounded-lg",attrs:{"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","large":"","fixed":"","bottom":"","right":""},on:{"click":_vm.scrollHistory}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-comments-alt")])],1)]}}]),model:{value:(_vm.chat),callback:function ($$v) {_vm.chat=$$v},expression:"chat"}},[_c('v-card',{staticClass:"chat-window d-flex flex-column justify-space-between pt-2"},[_c('v-btn',{staticClass:"close-btn mr-n4 mt-n2 pa-4",attrs:{"absolute":"","right":"","small":""},on:{"click":function($event){_vm.chat = false}}},[_c('v-icon',[_vm._v("far fa-times")])],1),_c('div',{staticClass:"chat-history pa-3 align-start flex-grow-1"},[(_vm.groupChatLoading && !_vm.chatHistory.length)?_c('div',{staticClass:"text-center py-10"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_c('div',_vm._l((_vm.chatHistory),function(ref,idx){
var _id = ref._id;
var name = ref.name;
var createdAt = ref.createdAt;
var message = ref.message;
return _c('div',{key:(name + "-" + idx),staticClass:"mb-4"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-3"},[(name === 'System')?_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("fal fa-robot")]):_c('b',[_vm._v(_vm._s(name))]),_vm._v(" "),_c('i',{staticClass:"caption"},[_vm._v(_vm._s(_vm.moment(createdAt).calendar()))])],1),(_vm.currentUser.admin)?_c('v-btn',{attrs:{"color":"error","loading":_vm.groupChatLoading,"small":"","absolute":"","right":""},on:{"click":function($event){_vm.deleteMessage(_id.toString(), name, createdAt)}}},[_vm._v("Delete")]):_vm._e()],1),_c('div',{staticClass:"ml-9 pt-1 body-2"},[_vm._v(_vm._s(message))])])}),0)]),_c('div',[_c('v-divider'),_c('v-textarea',{staticClass:"align-end rounded-0",attrs:{"rows":"3","autofocus":"","hide-details":"","filled":"","no-resize":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"message"}}),_c('v-btn',{staticClass:"rounded-0",attrs:{"loading":_vm.groupChatLoading,"x-large":"","block":""},on:{"click":_vm.submit}},[_c('v-icon',[_vm._v("fas fa-bullhorn")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }