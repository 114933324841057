<template>
  <div>
    <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2" class="mb-7">
      <v-overlay :value="fetchingUsers" absolute />
      <v-row class="text-subtitle-1">
        <v-col cols="4" class="pl-6">Hooligan</v-col>
        <v-col cols="3" class="text-center">Points</v-col>
        <v-col class="text-center">Punish / Reward</v-col>
      </v-row>
      <UserRow
        v-for="(user, idx) in users"
        :user="user"
        :key="`${user.id}-${idx}`"
        :class="{ 'grey darken-4 rounded-lg': user.approved && idx % 2 === 0 }"
      />
    </v-col>
  </div>
</template>

<script>
// import MongoRealmService from "@/services/mongoRealm.service"
import useUserAuth from "@/composables/useUserAuth"
import useUserPoints from "@/composables/useUserPoints"
// import useGroupChat from "@/composables/useGroupChat"
import UserRow from "@/components/UserRow"

export default {
  name: "Dashboard",
  components: {
    UserRow,
  },
  setup() {
    const { currentUser } = useUserAuth()
    const { getAllUsers, users, fetchingUsers } = useUserPoints()

    getAllUsers(currentUser.value.admin)
    // pull all app users 30 seconds to update order
    const interval = setInterval(() => getAllUsers(currentUser.value.admin), 1000 * 60)

    return { users, interval, fetchingUsers, currentUser }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>
