import { debugLog, debugError } from "@/utils/debugger.util"

const defaultResponse = { success: true, error: null, data: null }

export const requestWrapper = async (request, name) => {
  let resp = { ...defaultResponse }

  try {
    resp.data = await request()
    debugLog(`${name || "request"} success`, resp.data)
  } catch (error) {
    debugError(`${name || "request"} error`, error)
    resp.success = false
    resp.error = console
  }

  return resp
}
