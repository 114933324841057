import { ref } from "@vue/composition-api"
import MongoRealmService from "@/services/mongoRealm.service"
import { debugLog } from "@/utils/debugger.util"
import orderBy from "lodash/orderBy"

/* const db = MongoRealmService.connect()
const users = db.collection("users") */

const users = ref([])
const fetchingUsers = ref(false)

export default function() {
  const getAllUsers = async (isAdmin) => {
    fetchingUsers.value = true
    users.value = await MongoRealmService.getAllUsers(isAdmin)
    fetchingUsers.value = false
  }

  const handleUserChange = ({ id, points }) => {
    debugLog("handeUserChange", { id, points })
    // update the affected user
    users.value.find((user) => user.id === id).points = points
    users.value = orderBy(users.value, ["points", "firstName"], ["desc", "asc"])
  }

  const logPointsTransaction = (actingUser, targetUser, type) => {
    // log transaction
    const actingUserName = `${actingUser.firstName} ${actingUser.lastName.charAt(0)}`
    const targetUserName = `${targetUser.firstName} ${targetUser.lastName.charAt(0)}`
    const message = `${actingUserName} ${type} ${targetUserName}` // with ${Math.abs(
    // diff
    // )} points.`
    MongoRealmService.createChatMessage({ user: { id: 0, firstName: "System" }, message })
  }

  const reward = async ({ actingUser, targetUser, points }) => {
    points = Number(points) + 1
    // update the affected user
    // handleUserChange({ id: targetUser.id, points })
    await MongoRealmService.updateUserPoints({ id: targetUser.id, points })
    logPointsTransaction(actingUser, targetUser, "rewarded")
  }
  const punish = async ({ actingUser, targetUser, points }) => {
    points = Number(points) - 1

    // update the affected user
    // handleUserChange({ id: targetUser.id, points })
    await MongoRealmService.updateUserPoints({ id: targetUser.id, points })
    logPointsTransaction(actingUser, targetUser, "punished")
  }

  // watch for user changes
  // MongoRealmService.watchForUserChanges(handleUserChange)

  return {
    fetchingUsers,
    handleUserChange,
    getAllUsers,
    users,
    reward,
    punish,
  }
}
