<template>
  <div class="home">
    <Dashboard v-if="currentUser.approved" />
  </div>
</template>

<script>
// @ is an alias to /src
import useUserAuth from "@/composables/useUserAuth"
import Dashboard from "@/components/Dashboard.vue"

export default {
  name: "Home",
  components: {
    Dashboard,
  },
  setup() {
    const { currentUser } = useUserAuth()

    return { currentUser }
  },
}
</script>
