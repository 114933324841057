import { ref } from "@vue/composition-api"
import GoogleAuthService from "@/services/googleAuth.service"
import MongoRealmService from "@/services/mongoRealm.service"
import { debugLog } from "@/utils/debugger.util"
import { delay } from "@/utils/delay.util"
// import { debugError } from "@/utils/debugger.util"

const googleProfile = ref({}) // google
const currentUser = ref({}) // mongo
const authFailed = ref(false)
const isSignedIn = ref(false)

const authIsProcessing = ref(true)

export default function() {
  /**
   * Fetch user profile
   * @param {boolean} manualSignIn Indicates user clicked the sign in button
   */
  const getUserProfile = async (params = {}) => {
    authIsProcessing.value = true

    if (params.manualSignIn) {
      // add slight delay so user can see something is happening
      await delay(1000)
    }

    // ensure client is properly initialized
    try {
      await GoogleAuthService.init()
    } catch (error) {
      authFailed.value = true
      authIsProcessing.value = false

      return null
    }

    isSignedIn.value = GoogleAuthService.getSignedInState()
    // check auth state
    if (!isSignedIn.value) {
      // not signed in, attempt sign in
      await GoogleAuthService.signIn()
      isSignedIn.value = GoogleAuthService.getSignedInState()
    }

    googleProfile.value = await GoogleAuthService.getUserProfile()
    debugLog("Google Profile", googleProfile.value)
    // MongoRealmService.login(code)

    authIsProcessing.value = false
  }

  const signIn = async () => {
    debugLog("userAuth signIn()")
    return getUserProfile({ manualSignIn: true })
  }

  const signOut = async () => {
    debugLog("userAuth signOut()")
    await GoogleAuthService.signOut()
    isSignedIn.value = false
    googleProfile.value = {}
    currentUser.value = {}
    authFailed.value = false
  }

  const authenticateUser = async () => {
    await getUserProfile()

    if (isSignedIn.value) {
      let mongoUser = await MongoRealmService.getUserByEmail(googleProfile.value.email)
      debugLog("Existing Mongo User", mongoUser)
      if (!mongoUser) {
        // create the user
        mongoUser = await MongoRealmService.createUser(googleProfile.value)
      }

      currentUser.value = mongoUser
    }
  }

  return {
    authenticateUser,
    authIsProcessing,
    authFailed,
    getUserProfile,
    signIn,
    signOut,
    isSignedIn,
    googleProfile,
    currentUser,
  }
}
