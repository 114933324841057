<template>
  <v-app id="app">
    <v-app-bar app>
      <v-toolbar-title>
        <div v-if="currentUser.id" class="d-flex align-center">
          <v-img
            v-if="currentUser.avatar"
            :src="currentUser.avatar"
            :alt="currentUser.firstName"
            class="rounded-lg"
            max-width="40"
            elevation="2"
          />
          <div v-if="currentUser.approved" class="mx-2 font-weight-bold font-italic">
            Fuck you, {{ currentUser.firstName }}
          </div>
        </div>
        <!-- <div v-else>It's the Points</div> -->
      </v-toolbar-title>
      <v-spacer />
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
      <template v-if="!authIsProcessing">
        <v-btn @click="isSignedIn ? signOut() : signIn()" color="secondary">
          <span v-if="isSignedIn"><v-icon small>far fa-sign-out</v-icon></span>
          <span v-else><v-icon small>far fa-sign-in</v-icon></span>
        </v-btn>
      </template>
    </v-app-bar>

    <v-main :key="`${$route.fullPath}-${currentUser.id}`">
      <v-container>
        <!-- not signed in -->
        <template v-if="!currentUser.id">
          <div v-if="authIsProcessing" class="loader">
            <h3>Authenticating...</h3>
          </div>
          <v-alert v-else-if="authFailed" class="ma-5" type="error" prominent>
            Auth server connection failed. Please try again.
          </v-alert>
          <v-alert v-else-if="!isSignedIn" class="ma-5" type="success" prominent>
            You're signed out
          </v-alert>
          <div v-else class="loader">
            <h3><v-icon>fas fa-check</v-icon></h3>
          </div>
        </template>
        <div v-else id="router-view">
          <!-- signed in but not yet approved -->
          <v-card v-if="!currentUser.approved">
            <v-card-title>Account Approval Pending</v-card-title>
            <v-card-text>
              <p>Hey, {{ currentUser.firstName }}! Thanks for signing up.</p>
              <p>
                Sit tight while your account is reviewed for approval.
              </p>
            </v-card-text>
          </v-card>
          <transition name="fade" mode="out-in">
            <router-view :key="`${$route.fullPath}-${currentUser.id}`" />
          </transition>
        </div>
      </v-container>

      <GroupChat v-if="currentUser.id" />
    </v-main>
    <v-footer app absolute>
      <div class="text-center text-caption">&copy; {{ new Date().getFullYear() }} - Hooligan Media, LLC</div>
    </v-footer>
  </v-app>
</template>

<script>
// import { watch } from "@vue/composition-api"
import MongoRealmService from "@/services/mongoRealm.service"
import useUserAuth from "@/composables/useUserAuth"
import GroupChat from "@/components/GroupChat"
import useUserPoints from "@/composables/useUserPoints"
import useGroupChat from "@/composables/useGroupChat"

export default {
  name: "App",
  components: {
    GroupChat,
  },
  setup() {
    // first ensure mongodb connection
    // MongoRealmService.connect()

    const { authIsProcessing, authFailed, authenticateUser, signIn, signOut, isSignedIn, currentUser } = useUserAuth()
    const { handleUserChange } = useUserPoints()
    const { handleChatUpdate } = useGroupChat()

    // watch for user changes
    MongoRealmService.watchForUserChanges(handleUserChange)
    // watch for chat changes
    MongoRealmService.watchForChatChanges(handleChatUpdate)

    // initialize google auth script, attempt to auth on script load
    let gapiScript = document.createElement("script")
    gapiScript.setAttribute("src", "https://apis.google.com/js/api.js")
    gapiScript.async = true
    gapiScript.onload = authenticateUser
    document.head.appendChild(gapiScript)

    return {
      authIsProcessing,
      authFailed,
      authenticateUser,
      signIn,
      signOut,
      isSignedIn,
      currentUser,
    }
  },
}
</script>

<style lang="scss">
/* transition between route changes */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 1;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
